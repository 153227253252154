@import 'variables';

// Hide Arrows From Input Number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group {
  margin: 1em 0;
  .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #4f5867;
    font-family: BlueberrySans-Regular;
  }

  input {
    background: #ffffff;
    border: 1px solid #e5e5f0;
    border-radius: 6px;
    height: 2.8em;
  }

  .input-group-text {
    background: #ffffff;
    border-left-style: none;
    border: 1px solid #e5e5f0;
    border-radius: 0 6px 6px 0;
    height: 3.2em;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #4f5867 !important;
    font-family: BlueberrySans-Regular !important;
    justify-content: center;
  }

  *:focus {
    outline-style: none !important;
    box-shadow: none !important;
  }

  .input-group-text-disabled {
    color: rgba(79, 88, 103, 0.8) !important;
    background: transparent !important;
    border-left-style: none;
    border: 1px solid #e5e5f0;
    border-radius: 0 6px 6px 0;
    height: 3.2em;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: BlueberrySans-Regular !important;
  }

  .input-group-text-disabled2 {
    color: rgba(79, 88, 103, 0.8) !important;
    background: transparent !important;
    border-left-style: none;
    border: 1px solid #e5e5f0;
    border-radius: 6px 0 0 6px;
    height: 3.2em;
    font-weight: 400 !important;
    font-size: 14px !important;
    font-family: BlueberrySans-Regular !important;
  }

  ::-webkit-input-placeholder {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: rgba(79, 88, 103, 0.5) !important;
    font-family: BlueberrySans-Regular !important;
  }

  :-ms-input-placeholder {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: rgba(79, 88, 103, 0.5) !important;
    font-family: BlueberrySans-Regular !important;
  }

  ::placeholder {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: rgba(79, 88, 103, 0.5) !important;
    font-family: BlueberrySans-Regular !important;
  }

  .form-control:disabled {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: rgba(79, 88, 103, 0.8) !important;
    background-color: transparent !important;
    font-family: BlueberrySans-Regular !important;
    height: 3.2em !important;
  }

  .left-icon-input {
    position: absolute;
    margin: 10px;
    font-size: 20px;
    color: inherit;
  }

  .left-icon-input + .form-control {
    padding-left: 40px;
  }

  .custom-input-file {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e5e5f0;
    border-radius: 6px;
    height: 3.2em;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: rgba(79, 88, 103, 0.5);
    font-family: BlueberrySans-Regular;
    display: flex;
    align-items: center;
  }

  .color-input-black {
    color: black !important;
  }

  .color-input-primary {
    color: $base-color-primary !important;
  }

  .is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.select__single-value {
  font-size: 14px !important;
  text-align: 'left' !important;
}

.select__value-container {
  padding: 0 8px !important;
}

.select__control--is-disabled {
  background-color: transparent !important;
}

.rounded .select__control {
  border-radius: 30px !important;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper {
  border: 1px solid #e5e5f0;
  border-radius: 6px;
  margin: 20px 0;
  padding: 15px 20px 5px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #b8b8d6;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 3px #b8b8d6;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $base-color-primary;
  border: none;
}

/* When the checkbox is disabled, add a blue background */
.checkbox-container input:disabled ~ .checkmark {
  background-color: #e9ecef;
  border: none;
  opacity: 0.7;
  cursor: context-menu;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
}

.placeholder-black::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: black !important;
}

