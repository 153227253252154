// Scrollbar
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #c4c4c4;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #c4c4c4;
}
