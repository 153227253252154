body {
  overflow: auto;
}
.content-container-side {
  max-width: 100vw;
  padding: 10px 25px;
  .overflow {
    overflow-y: none;
    overflow-x: auto;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }
}

.content-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #4f5867;
}

.MuiButton-root {
  text-transform: capitalize !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.MuiBreadcrumbs-li {
  display: flex !important;
  align-items: center !important;
}

.MuiBreadcrumbs-separator::before {
  font-family: 'Material Icons';
  content: '\e5e1';
  color: rgba(79, 88, 103, 0.7);
  font-size: 12px;
  font-weight: 500;
}

.icon-modal {
  background-color: rgba(79, 88, 103, 0.4);
  color: #fff;
  padding: 8px;
  border-radius: 100%;
  font-size: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}

.modal {
  .modal-content {
    border-style: none !important;
    border-radius: 16px !important;
  }

  .modal-header {
    border-style: none !important;
  }

  .modal-footer {
    border-style: none !important;
  }
}

.Toastify__progress-bar--success {
  background-color: #2ec4b6 !important;
}

.MuiTabs-indicator {
  background-color: #2ec4b6 !important;
  color: #2ec4b6 !important;
  max-width: 45px !important;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
  color: #2ec4b6 !important;
}

.MuiButtonBase-root.MuiTab-root {
  padding-left: 0 !important;
  min-width: fit-content !important;
}

.card-border {
  border: 1px solid #e5e5f0 !important;
  border-radius: 16px !important;
  padding: 24px !important;

  .card-text {
    font-family: BlueberrySans-Regular;
    font-size: 14px;
    line-height: 140%;
    color: rgba(79, 88, 103, 0.8);
    margin-bottom: 0.5rem;
  }
}
