@font-face {
  font-family: BlueberrySans-Black;
  src: url('../fonts/blueberry_sans/BlueberrySans-Black.woff2');
}

@font-face {
  font-family: BlueberrySans-Bold;
  src: url('../fonts/blueberry_sans/BlueberrySans-Bold.woff2');
}

@font-face {
  font-family: BlueberrySans-ExtraBold;
  src: url('../fonts/blueberry_sans/BlueberrySans-ExtraBold.woff2');
}

@font-face {
  font-family: BlueberrySans-Light;
  src: url('../fonts/blueberry_sans/BlueberrySans-Light.woff2');
}

@font-face {
  font-family: BlueberrySans-Medium;
  src: url('../fonts/blueberry_sans/BlueberrySans-Medium.woff2');
}

@font-face {
  font-family: BlueberrySans-Regular;
  src: url('../fonts/blueberry_sans/BlueberrySans-Regular.woff2');
}

.font-style-black {
  font-family: BlueberrySans-Black;
}

.font-style-bold {
  font-family: BlueberrySans-Bold;
}

.font-style-extra-bold {
  font-family: BlueberrySans-ExtraBold;
}

.font-style-light {
  font-family: BlueberrySans-Light;
}

.font-style-medium {
  font-family: BlueberrySans-Medium;
}

.font-style-regular {
  font-family: BlueberrySans-Regular;
}