.modal-filter {
    .modal-footer {
        justify-content: flex-start !important;
        .btn-warning {
            background: #FEEBEB !important;
            color: red !important;
            border: none !important;
        }
    }
    
    .modal-body {
        .row {
            --bs-gutter-x: 0 !important;
        }
    }
    .title-filter {
        height: 50px;
        line-height: 50px;
        background: #F8F8FB;
        border: 1px solid #E5E5F0;
        border-radius: 8px;
        font-family: 'Blueberry Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #A3A3A3;
    }
}

.switch-container {
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
      }
      
      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      input:checked + .slider {
        background: #25AEA1;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #25AEA1;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}

.target-donation, .duration, .donation-goals {
    .br-red {
        border: 1px solid #dc3545 !important;
    }
}

.target-donation, .duration, .amount {
  .br-red {
      border: 1px solid #dc3545 !important;
  }
}

.export-btn {
  border: 1px solid #2EC4B6 !important;
}

.description-label {
  margin: 1em 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f5867;
  font-family: BlueberrySans-Regular;
}

.select__input-container {
  margin: 0 !important;
  padding: 0 !important;
}

.disabled .input-group{
  background-color: #F5F6FA;
}