.label {
    font-family: 'Blueberry Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
}

.Avatar {
    margin-left: 14px;
}