.black-100 {
  background-color: #4F5867;
  color: #4F5867;
}

.navy-100 {
  background-color: #41617E;;
  color: #41617E;
}

.tosca-100 {
  background-color: #2EC4B6 !important;
  color: #2EC4B6 !important;
}

.orange-100 {
  background-color: #F57D41;
  color: #F57D41;
}

.yellow-100 {
  background-color: #FFCC00;
  color: #FFCC00;
}
