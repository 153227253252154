.card-notification {
  padding: 30px;
  background: #ffffff;
  border-radius: 16px !important;

  .img-notification {
    width: 120px;
    height: 80px;
  }

  .card-notification-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #4f5867;
  }

  .item-notification-header {
    border: 1px solid #e5e5f0;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px;
    Link {
      text-decoration: none;
      color: #4f5867;
    }
  }

  .item-notification-body {
    border: 1px solid #e5e5f0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px;
    a {
      color: #4f5867;
    }

    .notification-img {
      width: 120px !important;
      height: 80px !important;
    }
  }

  .text-title {
    color: #2ec4b6;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
  .special {
    color: #f57d41;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
}

.timeline-notification {
  border-left: 0.5px dotted #4f5867;
  position: relative;
  .timeline-dot img {
    position: absolute;
    left: -9px;
    top: -10px;
  }
  .timeline-dot p {
    position: absolute;
    top: -14px;
  }
}
.box-notification {
  margin-bottom: 10px;
}
