.login-illustration {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.wrap {
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.login-illustration {
  width: 45%;
}

.login-wrap {
  width: 55%;
  padding: 100px 160px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4F5867;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(79, 88, 103, 0.7);
  }

  small-text {
    font-size: 14px;
    line-height: 18px;
    color: rgba(79, 88, 103, 0.5);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .login-wrap {
    width: 60%;
    padding: 70px;
  }
}

@media (max-width: 767.98px) {
  .wrap .login-illustration {
    // height: 250px;
    display: none;
  }

  .login-wrap {
    width: 100%;
    padding: 70px;
  }
}

// @media (max-width: 574.98px) {
//   .login-wrap {
//     padding: 70px;
//   }
// }

.login-wrap {
  position: relative;
  background: #fff;
}