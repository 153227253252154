.table-customize {
  width: 100%;
  margin-top: 20px;
}

.table-customize td,
.table th {
  background-color: #FFFFFF !important;
  padding: 16px !important;
  vertical-align: middle;
  border-bottom: 1px solid #dee2e6 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  color: #4F5867;
  font-family: BlueberrySans-Regular;
}

.table-customize thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6 !important;
  padding: 16px !important;
  font-size: 13px !important;
  line-height: 14px !important;
  color: #9DA6BA !important;
  font-weight: 500;
  font-family: BlueberrySans-Regular;
}

.btn-elipsis {
  background-color: transparent;
  font-weight: 800;
}

.label-sign {
  padding: 10px;
  border-radius: 5px;
}

.label-sign.label-sign-primary {
  background-color: #04AA59;
  color: #fff;
}

.label-sign.label-sign-error {
  background-color: #d00000;
  color: #fff;
}

.card-datatable {
  padding: 30px;
  background: #FFFFFF;
  border-radius: 16px !important;
  border-style: none !important;

  .card-datatable-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #4F5867;
  }
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  font-family: BlueberrySans-Regular;
}
