.search {
    --size: 35px;
    float: right;
    border: 2px solid var(#2EC4B6 !important);
    display: flex;
    border-radius: 100px;
    overflow: hidden;
    font-size: 0.25em;
    position: relative;
    width: var(--size);
    height: var(--size);
    // margin-left: auto;
    transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
    padding: 3px;
  }
  
  .search__input {
    float: right;
    border: 0;
    padding: .25em 1em;
    flex-grow: 1;
    outline: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent;
    opacity: 0;
    cursor: pointer;
    font-size: 1.0rem;
  }
  
  .search__submit {
    float: right;
    font-size: 0.25rem;
    margin-left: auto;
    background: 0;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    transition: background 200ms ease-out;
    width: calc(var(--size) - 10px);
    height: calc(var(--size) - 10px);
    color: var(#2EC4B6 !important);
  }
  
  .search:focus-within {
    // box-shadow: 0 0 5px var(--clr-primary);
    width: 35%;
    
    .search__input {
      opacity: 1;
      z-index: initial;
      cursor: initial;
      width: calc(100% - var(--size));
    }
    
    .search__submit {
      background: var(--clr-primary);
      color: #2EC4B6 !important;
      box-shadow: 0 0 10px rgba(0,0,0,.15);
      // transition: transform 500ms ease-out;
      
      &:hover,
      &:focus {
        outline: 3;
        // transform: rotate(1turn);
        box-shadow: 0 0 10px rgba(0,0,0,.65);
      }
    }
  }