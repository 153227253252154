.sidebar-container {
  overflow-y: auto;
  width: 255px;
  height: 776px;
  a {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: rgba(79, 88, 103, 0.7);
      text-decoration: none;
      padding: 15px;
  
      &:hover {
        text-decoration: none;
        color: hsl(34, 81%, 47%);
      }
    }
  .active {
    color: hsl(34, 81%, 47%);
    text-decoration: none;
    background: linear-gradient(89.93deg, rgba(238, 169, 80, 0.27) 2.77%, rgba(238, 169, 80, 0.0459) 109.06%);
    border-bottom-left-radius: 22px;
    border-top-left-radius: 22px;

    &::after {
      content: '';
      background-color: rgba(245, 125, 65, 0.7);
      width: 5px;
      height: 35px;
      border-radius: 100px;
      position: absolute;
      right: 22px;
      margin-left: 10px;
    }
  }

  .bg-none {
    background: none !important;
  }

  .bg-none::after {
    content: none;
 }
}

.main-top {
  padding-left: 108px;
  max-width: 100vw;
  @media (max-width: 920px) {
    padding-left: 0;
  }
}