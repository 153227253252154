@import "variables";

.btn-primary {
  background-color: $base-color-primary  !important;
  border-color: $base-color-primary  !important;
  font-size: 13px !important;
  line-height: 140% !important;
  font-family: BlueberrySans-Regular !important;

  &:focus {
    outline-style: none !important;
    box-shadow: none !important;
  }
}

.btn-primary:hover,
.btn-primary:active {
  background-color: $base-color-primary  !important;
  border-color: $base-color-primary  !important;
}

.btn-outline-primary {
  background-color: #fff !important;
  border-color: $base-color-primary  !important;
  color: $base-color-primary  !important;
  font-size: 13px !important;
  line-height: 140% !important;
  font-family: BlueberrySans-Regular !important;

  &:focus {
    outline-style: none !important;
    box-shadow: none !important;
  }
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: #fff;
  border-color: $base-color-primary  !important;
  color: $base-color-primary  !important;
}

.btn-warning {
  background-color: $base-color-nonactivated  !important;
  border-color: $base-color-nonactivated  !important;
  color: #fff !important;
  font-size: 13px !important;
  line-height: 140% !important;
  font-family: BlueberrySans-Regular !important;

  &:focus {
    outline-style: none !important;
    box-shadow: none !important;
  }
}

.btn-warning:hover,
.btn-warning:active {
  background-color: $base-color-nonactivated  !important;
  border-color: $base-color-nonactivated  !important;
  color: #fff !important;
}

.btn-cancel {
  background-color: #fff !important;
  border-color: $base-color-grey  !important;
  color: #000  !important;
  font-size: 13px !important;
  line-height: 140% !important;
  font-family: BlueberrySans-Regular !important;

  &:focus {
    outline-style: none !important;
    box-shadow: none !important;
  }
}

.btn-cancel:hover,
.btn-cancel:active {
  background-color: #fff !important;
  border-color: $base-color-grey  !important;
  color: #000  !important;
}

.left-icon {
  margin-right: 8px;
  font-size: 16px;
}

.right-icon {
  margin-left: 8px;
  font-size: 16px;
}

.btnFilterActive {
  position: relative;
  .MuiButton-root.MuiButton-outlined {
    border: 1px solid #2EC4B6 !important;
  }
}

.circleRightBtn {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  background: #F57D41;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  z-index: 99;
}