@import url('../styles/_variables.scss');
// Pagination
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
}

.page-item {
  margin-left: 5px;
  margin-right: 5px;
}

.page-link {
  position: relative;
  display: block;
  text-align: center;
  margin-left: 8px;
  line-height: 1.25;
  color: #495057 !important;
  background-color: #f7f8f8;
  border: none;
  border-radius: 8px;
  height: 40px;
  min-width: 40px;
}

.page-link:hover {
  z-index: 2;
  color: #2EC4B6;
  text-decoration: none;
  background-color: #f7f8f8;
  border: solid 1px #2EC4B6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: transparent;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #2EC4B6;
  text-decoration: none;
  background-color: #f7f8f8;
  border: solid 1px #2EC4B6;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
}

.pagination .previous.disabled,
.pagination .previous.disabled:hover,
.pagination .next.disabled,
.pagination .next.disabled:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.pagination .previous .page-link,
.pagination .next .page-link {
  background-color: #2EC4B6  !important;
  border-color: #2EC4B6  !important;
  color: white !important;
  box-shadow: none !important;
  padding: 8px 10px !important;
}

.pagination .previous.disabled .page-link,
.pagination .next.disabled .page-link {
  background-color: #a2dbbf !important;
  border-color: #a2dbbf !important;
  color: white;
  box-shadow: none !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #2EC4B6  !important;
  background-color: #f7f8f8 !important;
  border-color: #2EC4B6  !important;
}

.mini-hide {
  display: block;
  @media (max-width: 920px) {
    display: none !important;
  }
}

.mini-show {
  display: none;
  @media (max-width: 920px) {
    display: block !important;
  }
}
