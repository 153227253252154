.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f5867;
  font-family: BlueberrySans-Regular;
}

.button-img {
  background: none;
  border: none;
}

